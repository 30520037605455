<!--
 * @Description: 运费模板
 * @Author: 琢磨先生
 * @Date: 2022-06-08 15:48:16
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-14 03:28:50
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box">
    <el-table :data="tableData">
      <el-table-column label="编号" prop="id" width="80"> </el-table-column>
      <el-table-column label="名称" prop="name" width="160"> </el-table-column>
      <el-table-column label="默认" width="80">
        <template #default="scope">
          {{ scope.row.is_default ? "是" : "" }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template #default="scope">
          <el-tag
            :type="scope.row.is_stop ? 'danger' : 'success'"
            size="small"
            >{{ scope.row.is_stop ? "停用" : "正常" }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column label="包邮配送区域" width="400">
        <template #default="scope"
          > 
          <el-tag size="small"
            v-for="item in scope.row.contents.filter((x) => x.type == 0)"
            :key="item.id"
            style="margin: 0 5px 5px 0"
            >{{ item.province.name }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="不配送区域" width="200">
        <template #default="scope">
          <el-tag  size="small"
            type="danger"
            v-for="item in scope.row.contents.filter((x) => x.type == 2)"
            :key="item.id"
            style="margin: 0 5px 5px 0"
            >{{ item.province.name }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-carriage :item="current" :reload="loadData"></edit-carriage>
</template>
<script>
import EditCarriage from "./edit_carriage.vue";

export default {
  components: {
    EditCarriage,
  },
  data() {
    return {
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.post("admin/v1/carriage").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("admin/v1/carriage/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>